@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-news {
    img {
        width: 100%;
    }
    #list-news {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        justify-items: center;
        @media(min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .controls {
        margin-top: 50px;
        text-align: center;

        #back {
            position: relative;
            &::before {
                content: "\ea3e";
                font-size: 18px;
                position: absolute;
                right: 10px;
                font-family: icomoon;
                transform: rotate(90deg);
            }
        }
        #next {
            position: relative;
            &::before {
                content: "\ea3e";
                font-size: 18px;
                position: absolute;
                left: 10px;
                font-family: icomoon;
                transform: rotate(270deg);
            }
        }
    }

    .card-new {
        width: 90%;
        margin: 20px 0 50px 0;
        border: 1px solid black;
        box-shadow: 1px 6px 15px -1px #000000;
        min-height: 430px;
        border-radius: 20px 20px 0 0;
        @media(min-width: $md) {
            min-height: 450px;
        }
        &:hover {
            box-shadow: 1px 6px 25px -1px #000000;
        }
        a {
            &:hover {
                h3 {
                    color: $textColorPrimary;
                }
            }
            .image {
                img {
                    border-radius: 20px 20px 0 0;
                    width: 100% !important;
                }
            }
            .text {
                padding: 20px;
            }
        }
    }
}