@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-commit {
    p {
       @include fontSize(15,22);
       font-family: $fontLight;
    }
    img {
        width: 100%;
        margin-top: 30px;
    }
}