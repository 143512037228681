@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";
.block-form {
    padding: 20px;
    body {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        
    }
    .container {
        width: 100%;
        border-radius: 7px;
        border: 1px solid $bg-blue;
        text-align: center;
        padding: 30px 35px 0 35px;
        overflow: hidden;
        @media(min-width: $md) {
            width: 35%;
        }

        .progress-bar {
            display: flex;
            justify-content: space-around;
            margin: 20px 0;

            .step {
                text-align: center;
                width: 100%;

                p {
                    @include fontSize(12,15);
                }

                .num {
                    position: relative;
                    width: 25px;
                    height: 25px;
                    border: 2px solid $bg-blue;
                    border-radius: 50%;
                    display: inline-block;
                    @include fontSize(16,20);
                }
            }
            
        }

        .form-princ {
            width: 100%;
            transition: margin-left 0.8s ease-in-out;
            form {
                display: flex;
                width: 660%;
    
                .page {
                    width: 100%; 
                    margin-right: 10%;

                    .form-page {
                        display: none;
                    }
                    button {
                        font-weight: bold;
                        width: 100%;
                        background-color: $bg-blue;
                        border: 1px solid $bg-blue;
                        border-radius: 5px;
                        padding: 10px;
                        margin: 20px 0 20px 0;
                        color: $textColorWhite;
                        transition: 0.5s ease;
                        &:first-child {
                            margin-bottom: 0;
                        }

                        &:hover {
                            background-color: $bg-primary;
                            color: $textColorBlue;
                            cursor: pointer;
                        }
                        &[disabled] {
                            background-color: gray;
                            color: $textColorWhite;
                            border: none;
                        }
                    }

                    .textfield {
                        label{
                            font-weight: initial;
                            width: 100%;
                            background-color: $bg-gray;
                            border: 1px solid $bg-blue;
                            border-radius: 5px;
                            padding: 10px;
                            margin: 20px 0 20px 0;
                            color: $textColorWhite;
                            transition: 0.5s ease;
                            &:first-child {
                                margin-bottom: 0;
                            }

                            &:hover {
                                color: $textColorPrimary;
                                cursor: pointer;
                            }
                        }
                        input[type="checkbox"] {
                            display: none;
                        }
                    }

                    .select {
                        width: 100%;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: 20px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .bttn-nav {
                        display: flex;
                        justify-content: space-around;
                        margin-top: 20px;
                    }

                    .bttn-nav-send {
                        p {
                            &:last-child {
                                display: none;
                            }
                        }
                    }

                    .form-block {
                        position: relative;

                        input {
                            border: 1px solid gray;
                            border-radius: 6px;
                            position: relative;
                            width: 200px;
                            margin: 10px;
                            line-height: 6ex;
                            width: 100%;
                        }

                        label {
                            position: absolute;
                            top: -1.5ex;
                            z-index: 1;
                            left: 2em;
                            background-color: white;
                            padding: 0 5px;
                        }

                        &.bill {
                            display: none;
                            margin-bottom: 20px;
                        }
                        &.virtual-key, &.virtual-access, &.fault-cold, &.fault-hot, &.fault-heat  {
                            display: none;
                            align-items: left;
                            label {
                                position: initial;
                                width: 90%;
                                text-align: left;
                            }
                        }
                    }
                    .text-fault {
                        display: none;
                        text-align: left;
                    }

                    textarea {
                        width: 100% !important;
                        height: 150px;
                    }
                }
            }
        }
    }

    $margin-page1: -165%;
    $margin-page2: -330%;
    $margin-page3: -495%;

    .move-page1 {
        margin-left: $margin-page1;
        transition: margin-left 0.8s ease-in-out;
    }

    .move-page2 {
        margin-left: $margin-page2;
        transition: margin-left 0.8s ease-in-out;
    } 
    .move-page3 {
        margin-left: $margin-page3;
        transition: margin-left 0.8s ease-in-out;
    }

    .showForm {
        display: block !important;
    }
    .showFormFlex {
        display: flex !important;
    }

    .green-num {
        color: $textColorPrimary;
    }

     
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    
    
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    
        
        .close {
            position: absolute;
            right: 5px;
            top: -10px;
            color: #aaaaaa;
            font-size: 28px;
            font-weight: bold;
    
            &:hover,
            &:focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
    }
}