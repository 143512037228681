@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-accordion {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    .button-budget {
        margin-bottom: 20px;
    }
    .card {
        width: 100%;
        .img-bg-1 {
            position: relative;
            &::before {
                content: "";
                background-image: url(../images/products/cuando-instalar-rcc.webp);
                position: absolute;
                left: 0;
                right: 0;
                z-index:0;
                width: 100%;
                background-size: cover;
                @media(min-width: $md) {
                    height: 100%;
                }
            }
            .text {
                z-index: 1;
                width: 100%;
                @media(min-width: $md) {
                    width: 75%;
                }
            }
        }
        .img-bg-2 {
            position: relative;
            &::before {
                content: "";
                background-image: url(../images/products/porque-instalar-rcc.webp);
                position: absolute;
                left: 0;
                right: 0;
                z-index:0;
                width: 100%;
                background-size: cover;
                @media(min-width: $md) {
                    height: 100%;
                }
            }
            .text {
                z-index: 1;
                width: 100%;
                @media(min-width: $md) {
                    width: 75%;
                }
            }
        }
        .img-bg-3 {
            position: relative;
            @media(min-width: $md) {
                height: 260px;
            }
            &::before {
                content: "";
                background-image: url(../images/products/ahorrar-consumo.webp);
                position: absolute;
                left: 0;
                right: 0;
                z-index:0;
                width: 100%;
                background-size: cover;
                @media(min-width: $md) {
                    height: 100%;
                }
            }
            .text {
                z-index: 1;
                width: 100%;
                @media(min-width: $md) {
                    width: 75%;
                }
            }
        }

        &-body {
            display: block;
            @media(min-width: $md) {
                display: flex !important;
            }
            &_text {
                padding: 5px; 
                &--title {
                    font-weight: bold;
                    color: $textColorBlue;
                }    
            }
        }
        p {
            width: 100%;
            font-family: $fontLight;
            @include fontSize(15,22);
            a {
                display: block;
                padding: 30px 60px 33px 10px;
                background-color: white;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 2px solid green;
                position: relative;
                width: 100%;
                font-family: $fontBold;
                @include fontSize(16,23);
                &:hover{
                    cursor: pointer;
                }
                @media(min-width: $md) {
                    padding: 30px;
                }

                &::after {
                    content: "\ea3e";
                    font-size: 18px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    font-family: icomoon;
                    transition: transform .2s ease-in-out;
                }
                
  
                &[aria-expanded="true"]::after {
                    content: "\ea3e";
                    font-size: 18px;
                    position: absolute;
                    right: 10px;
                    font-family: icomoon;
                    transform: rotate(180deg);
                    transition: transform .2s ease-in-out;
                }
            }
        }
        .card-body {
            &_img {
                margin: 20px 0 0 0;
                @media(min-width: $md) {
                    margin: 0 0 0 0;
                }
            }
        }
    }
}