@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-valves {
    .bttn-text {
        margin-bottom: 30px;
    }

    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }

    .button-budget {
        margin-top: 50px;
    }

    &_img {
        text-align: center;
        img {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}