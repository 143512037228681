*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 736px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 956px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1164px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1264px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1300px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1299.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1300px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1300px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1300px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1300px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1300px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1200px !important;
  }
  .container {
    min-width: 1200px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

@font-face {
  font-family: 'RobotoLight';
  src: url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold';
  src: url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?si5qyp");
  src: url("../fonts/icomoon/icomoon.eot?si5qyp#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?si5qyp") format("truetype"), url("../fonts/icomoon/icomoon.woff?si5qyp") format("woff"), url("../fonts/icomoon/icomoon.svg?si5qyp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
  color: #000000;
}

.icon-menu:before {
  content: "\e9bd";
  color: #000000;
}

.icon-close-menu:before {
  content: "\ea0f";
  color: #000000;
}

.icon-arrow-up:before {
  content: "\ea3a";
  color: #000000;
}

.icon-arrow-down:before {
  content: "\ea3e";
  color: #000000;
}

body {
  overflow-x: hidden;
  position: relative;
}

@media (min-width: 992px) {
  body::before {
    content: "91 303 00 00";
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 24px;
    line-height: 28px;
    color: #1d2932;
    background-color: #00c95d;
    z-index: 2;
    padding: 15px;
    border-radius: 0 5px 0 0;
  }
}

section {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  section {
    margin-bottom: 50px;
  }
}

strong {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
}

h1 {
  font-size: 20px;
  line-height: 27px;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  color: #1d2932;
  text-align: center;
}

@media (min-width: 992px) {
  h1 {
    font-size: 25px;
    line-height: 32px;
  }
}

h2 {
  font-size: 18px;
  line-height: 25px;
  color: #00c95d;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  text-align: center;
  margin: 20px 0 20px 0;
}

@media (min-width: 992px) {
  h2 {
    font-size: 20px;
    line-height: 27px;
    text-align: left;
  }
}

h3 {
  font-size: 16px;
  line-height: 23px;
  color: #1d2932;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  text-align: left;
}

@media (min-width: 992px) {
  h3 {
    font-size: 18px;
    line-height: 25px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #1d2932;
}

a:hover {
  text-decoration: none;
  color: #1d2932;
}

.button-budget {
  background-color: #00c95d;
  border: 3px solid #1d2932;
  color: #1d2932;
  font-family: RobotoBold;
  font-size: 14px;
  line-height: 21px;
  padding: 7px;
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  text-align: center;
}

@media (min-width: 992px) {
  .button-budget {
    display: initial;
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    padding: 15px;
  }
}

.button-budget:hover {
  background-color: #1d2932;
  border: 3px solid #00c95d;
  color: #00c95d;
}

body {
  position: relative;
}

.header {
  margin-bottom: initial;
}

.header .nav {
  background: #ffffff;
  justify-content: space-between;
  padding: 10px;
}

@media (min-width: 992px) {
  .header .nav {
    width: 100%;
  }
}

.header .nav_logo img {
  width: 199px;
  height: 46px;
}

@media (min-width: 992px) {
  .header .nav_logo img {
    width: 250px;
    height: 60px;
  }
}

.header .nav ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: right;
}

.header .nav ul li {
  display: inline-block;
  cursor: pointer;
  padding: 6px;
}

.header .nav ul li a {
  text-transform: uppercase;
  color: #000000;
  font-family: RobotoLight;
  text-decoration: none;
  display: block;
  padding: 13px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 992px) {
  .header .nav ul li a {
    padding: 16px;
  }
}

.header .nav ul li a:hover {
  color: #00c95d;
}

.header .nav ul li a:last-child {
  padding-right: 0;
}

.header .nav .icon-menu, .header .nav .icon-close-menu {
  display: none;
}

@media (max-width: 992px) {
  .header .nav .icon-menu, .header .nav .icon-close-menu {
    display: block;
    text-align: right;
    padding: 10px 15px;
    font-size: 24px;
    cursor: pointer;
  }
  .header .nav ul {
    display: none;
    background: #ffffff;
  }
  .header .nav ul li {
    width: 100%;
    text-align: left;
    padding: 0px;
  }
  .header .nav .drop .fa-sort-desc {
    display: none;
  }
}

.header-2 {
  margin-bottom: initial;
  position: relative;
}

.header-2::before {
  content: "";
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header-2 .nav {
  background: #ffffff;
  justify-content: space-around;
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .header-2 .nav {
    width: 100%;
    padding: 10px 0;
  }
}

.header-2 .nav_logo img {
  width: 200px;
  height: 50px;
}

@media (min-width: 992px) {
  .header-2 .nav_logo img {
    width: 250px;
    height: 60px;
  }
}

.header-2 .nav .responsive-menu {
  position: relative;
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: right;
}

@media (min-width: 992px) {
  .header-2 .nav .responsive-menu {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: right;
  }
}

@media (min-width: 992px) {
  .header-2 .nav .responsive-menu .online {
    display: none;
  }
}

.header-2 .nav .responsive-menu li {
  display: inline-block;
  cursor: pointer;
  padding: 6px;
}

@media (min-width: 992px) {
  .header-2 .nav .responsive-menu li {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
}

.header-2 .nav .responsive-menu li a {
  text-transform: uppercase;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  color: #000000;
  text-decoration: none;
  display: block;
  padding: 16px;
  font-weight: bold;
}

.header-2 .nav .responsive-menu li a:hover {
  color: #00c95d;
}

.header-2 .nav .responsive-menu li a:last-child {
  padding-right: 0;
}

.header-2 .nav .responsive-menu .submenu {
  display: none;
  z-index: 2;
  background-color: white;
  left: 0;
  right: 0;
  top: inherit;
  text-align: left;
  padding: 10px;
  font-size: 16px;
  line-height: 23px;
}

@media (min-width: 992px) {
  .header-2 .nav .responsive-menu .submenu {
    position: absolute;
    box-shadow: none;
    border-top: 2px solid #1d2932;
    border-bottom: 2px solid #1d2932;
    top: 100%;
  }
}

.header-2 .nav .responsive-menu .active {
  display: block;
}

.header-2 .nav .icon-menu, .header-2 .nav .icon-close-menu {
  display: none;
}

@media (max-width: 992px) {
  .header-2 .nav .icon-menu, .header-2 .nav .icon-close-menu {
    display: block;
    text-align: right;
    padding: 10px 15px;
    font-size: 24px;
    cursor: pointer;
  }
  .header-2 .nav ul {
    display: none;
    background: #ffffff;
  }
  .header-2 .nav ul li {
    width: 100%;
    text-align: left;
    padding: 0px;
  }
  .header-2 .nav .drop .fa-sort-desc {
    display: none;
  }
}

.footer {
  background-color: #1d2932;
  display: flex;
  flex-direction: column;
  margin-bottom: initial;
  padding: 45px 25px 25px 25px;
}

.footer .footer-title a {
  color: #00c95d;
}

.footer .footer-title a:not([href]):not([tabindex]):hover, .footer .footer-title a:not([href]):not([tabindex]):focus {
  color: #00c95d;
}

@media (min-width: 992px) {
  .footer {
    margin-top: 50px;
    flex-direction: column;
    justify-content: space-between;
  }
}

.footer-container_img {
  width: 100%;
  text-align: center;
}

@media (min-width: 1200px) {
  .footer-container_img {
    text-align: end;
  }
}

.footer-container_img img {
  width: 50%;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .footer-container_img img {
    width: 30%;
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .footer-container_img img {
    width: 25%;
    margin-bottom: inherit;
  }
}

.footer-container_media {
  width: 100%;
}

@media (min-width: 992px) {
  .footer-container_media {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .footer-container_media {
    width: 100%;
    justify-content: end;
  }
}

.footer-container_media-nav {
  width: 100%;
}

@media (min-width: 992px) {
  .footer-container_media-nav {
    width: 20%;
  }
}

.footer-container_media-nav-media {
  display: flex;
  padding: 30px 0 0 0;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  list-style: none;
}

.footer-container_media-nav-media img {
  width: 60%;
}

.footer-container_options {
  width: 100%;
}

@media (min-width: 1200px) {
  .footer-container_options {
    width: 65%;
  }
}

.footer-container_options-nav {
  display: none;
}

@media (min-width: 992px) {
  .footer-container_options-nav {
    display: flex;
    justify-content: space-around;
    align-items: inherit;
  }
}

.footer-container_options-nav-media {
  list-style: none;
  padding: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .footer-container_options-nav-media {
    width: 20%;
    min-height: 400px;
  }
}

.footer-container_options-nav-media--green a {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  position: relative;
  color: #00c95d !important;
}

.footer-container_options-nav-media--green a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #00c95d;
  bottom: -10px;
  left: 0;
}

.footer-container_options-nav-media--green .soft-green {
  font-weight: 100;
}

.footer-container_options-nav-media--green .soft-green span {
  font-weight: bold;
}

.footer-container_options-nav-media li {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-container_options-nav-media li a {
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
}

.footer-container_options-nav-media li .soft-white {
  font-weight: 100;
  font-family: RobotoLight;
}

.footer-container_options-nav-media li .soft-white span {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
}

.footer-container_options-nav-media li .soft-white:hover span {
  color: #00c95d;
}

@media (min-width: 992px) {
  .footer-container_options-responsive {
    display: none;
  }
}

.footer-container_copy p {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
}

@media (min-width: 1200px) {
  .footer-container_copy p {
    font-size: 18px;
    line-height: 25px;
    text-align: right;
  }
}

.collapsible-link {
  width: 100%;
  position: relative;
  text-align: left;
}

.collapsible-link::after {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 0;
  font-family: icomoon;
  transition: transform .2s ease-in-out;
}

.collapsible-link::before {
  content: '';
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}

.collapsible-link:last-child {
  margin-bottom: 30px;
}

.collapsible-link[aria-expanded="true"]::after {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 0;
  font-family: icomoon;
  transform: rotate(180deg);
  transition: transform .2s ease-in-out;
}

.block-slider .swiper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.block-slider .swiper .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.block-slider .swiper .swiper-wrapper .swiper-slide {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-slider .swiper .swiper-wrapper .swiper-slide p img {
  width: 100%;
}

@media (min-width: 992px) {
  .block-slider .swiper {
    overflow: initial;
  }
}

.block-slider .swiper-pagination {
  position: relative !important;
  width: 100%;
}

.block-slider .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 11px;
}

.block-slider .swiper-pagination-bullet-active {
  background: #00c95d;
}

@media (min-width: 992px) {
  .block-slider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 21px;
  }
}

.block-slider .swiper-pagination-bullet:first-child {
  margin-left: 0;
}

.block-slider .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.block-slider-cards {
  overflow: hidden;
}

@media (min-width: 992px) {
  .block-slider-cards {
    overflow: initial;
  }
}

.block-slider-cards .swiper-info-install {
  margin-top: 35px;
}

.block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide {
  box-shadow: 1px 6px 15px -1px #000000;
  min-height: 430px;
}

@media (min-width: 992px) {
  .block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide {
    min-height: 450px;
  }
}

.block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide .swiper-slide_img img {
  width: 100% !important;
}

.block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide_text {
  padding: 20px;
}

.block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide_text p {
  font-size: 15px;
  line-height: 22px;
}

.block-slider-cards .swiper-info-install .swiper-wrapper .swiper-slide_text h2 {
  color: #1d2932;
  text-align: left;
  margin: initial;
}

.block-media h2 {
  text-align: center;
  color: #1d2932;
}

.block-media .swiper-media {
  overflow: hidden;
  margin-top: 35px;
}

@media (min-width: 992px) {
  .block-media .swiper-media {
    overflow: initial;
  }
}

.block-media .swiper-media .swiper-wrapper .swiper-slide a p {
  text-align: center;
}

.block-customer {
  position: relative;
}

.block-customer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1d2932;
  border-top: 5px solid #00c95d;
  border-bottom: 5px solid #00c95d;
  right: 0;
  left: 0;
}

.block-customer h2 {
  margin-top: 40px;
  text-align: center;
}

.block-customer .card {
  padding: 40px;
}

.block-customer .card a img {
  width: 70%;
}

@media (min-width: 480px) {
  .block-customer .card a img {
    width: 45%;
  }
}

@media (min-width: 992px) {
  .block-customer .card a img {
    width: 100%;
  }
}

.block-opinions h2 {
  text-align: center;
  color: #1d2932;
}

.block-opinions .swiper-opinions {
  overflow: hidden;
  width: 100%;
  padding: 0 0 50px 0;
}

@media (min-width: 992px) {
  .block-opinions .swiper-opinions {
    overflow: initial;
  }
}

@media (min-width: 992px) {
  .block-opinions .swiper-opinions {
    padding: 50px 0 70px 0;
  }
}

.block-opinions .swiper-opinions .swiper-slide {
  box-shadow: 1px 6px 15px -6px #000000;
  background-position: center;
  background-size: cover;
  padding: 20px;
  border-radius: 8px;
}

.block-opinions .swiper-opinions .swiper-slide_img img {
  width: 50%;
}

.block-opinions .swiper-opinions .swiper-slide_title {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  color: #1d2932;
  width: 90%;
  font-style: italic;
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 1200px) {
  .block-opinions .swiper-opinions .swiper-slide_title {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.block-opinions .swiper-opinions .swiper-slide_subtitle {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  color: #1d2932;
  width: 80%;
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media (min-width: 1200px) {
  .block-opinions .swiper-opinions .swiper-slide_subtitle {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    min-height: 50px;
    max-height: 50px;
  }
}

.block-services {
  margin-top: 30px;
  position: relative;
}

@media (min-width: 992px) {
  .block-services {
    margin-top: 50px;
  }
}

.block-services h2 {
  margin-bottom: 40px;
}

.block-services .card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1200px) {
  .block-services .card {
    position: absolute;
    justify-content: space-around;
    right: -20%;
    z-index: 1;
  }
}

.block-services .card_item {
  box-shadow: 1px 6px 15px -6px #000000;
  border-radius: 8px;
  padding: 15px;
  width: 80%;
  margin-top: 20px;
}

.block-services .card_item:first-child {
  margin-top: 0;
}

.block-services .card_item a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1200px) {
  .block-services .card_item a {
    flex-direction: row;
  }
}

.block-services .card_item a span {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .block-services .card_item {
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid #00c95d;
    border-radius: 8px;
    min-width: 500px;
    max-width: 500px;
  }
}

.block-services .img {
  display: none;
}

@media (min-width: 1200px) {
  .block-services .img {
    display: block;
  }
}

.block-energy p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-energy_img {
  width: 100%;
}

.block-energy_img img {
  width: 100%;
  text-align: center;
  position: relative;
}

@media (min-width: 992px) {
  .block-energy_img img {
    width: 70%;
  }
}

.block-energy_img--usage {
  width: 100%;
  text-align: center;
  margin: 20px 0 30px 0;
}

@media (min-width: 992px) {
  .block-energy_img--usage {
    width: 80%;
  }
}

.block-energy_img--usage img {
  width: 100%;
}

.block-remote p, .block-remote ul {
  font-size: 15px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
}

.block-remote_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .block-remote_img {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .block-product-water h3 {
    min-height: 50px;
  }
}

.block-product-water_text {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .block-product-water_text {
    display: block;
  }
}

@media (min-width: 1200px) {
  .block-product-water_text {
    display: flex;
  }
}

.block-product-water_text--text {
  width: 100%;
}

@media (min-width: 1200px) {
  .block-product-water_text--text {
    width: 80%;
  }
}

.block-product-water_text--text p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-product-water_text--img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .block-product-water_text--img {
    width: 20%;
    height: 20%;
    margin-top: inherit;
  }
}

.block-product-water_text--img img {
  width: 25%;
}

@media (min-width: 992px) {
  .block-product-water_text--img img {
    display: none;
  }
}

@media (min-width: 1200px) {
  .block-product-water_text--img img {
    display: block;
    width: 50%;
  }
}

.block-product-water_banner {
  height: 50px;
  width: 100%;
  padding: 10px;
  position: relative;
  text-align: center;
}

.block-product-water_banner-title {
  color: #1d2932;
  font-size: 20px;
  line-height: 25px;
}

@media (min-width: 992px) {
  .block-product-water_banner-title {
    font-size: 30px;
    line-height: 35px;
  }
}

.block-product-water_banner-title::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 50px solid transparent;
  border-bottom: 3px solid #00c95d;
}

.block-product-water_products {
  margin-top: 70px;
  margin-bottom: 70px;
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .block-product-water_products {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.block-product-water_products-item {
  margin-top: 40px;
  -webkit-box-shadow: 0px 3px 9px 6px #bdbdbd;
  -moz-box-shadow: 0px 3px 9px 6px #bdbdbd;
  box-shadow: 0px 3px 9px 6px #bdbdbd;
  border-radius: 3%;
  display: flex;
  flex-direction: column;
  padding: 10%;
}

@media (min-width: 768px) {
  .block-product-water_products-item {
    width: 45%;
    padding: 2%;
    margin-top: 2%;
  }
}

@media (min-width: 992px) {
  .block-product-water_products-item {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .block-product-water_products-item {
    width: 30%;
  }
}

.block-product-water_products-item span {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-product-water_products-item--img {
  min-height: 200px;
}

.block-product-water_products-item--img img {
  width: 100%;
}

.block-product-water_products-item span {
  font-size: 14px;
  line-height: 20px;
}

.block-product-water_products-item--type {
  padding: 0;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

@media (min-width: 992px) {
  .block-product-water_products-item--type {
    min-height: 120px;
  }
}

.block-product-water_products-item--type li {
  width: 100%;
}

.block-product-water_products-item--type li a {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 16px;
  line-height: 23px;
  margin: 0 6px 10px 0;
  color: #1d2932;
  border: 1px solid #1d2932;
  text-decoration: none;
  padding: 4px 8px 4px 8px;
  border-radius: 3px;
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.block-product-water_products-item--type li a:hover {
  background-color: #00c95d;
  color: #1d2932;
}

.block-product-water_products-item .nav-pills {
  margin-bottom: 30px;
  justify-content: center;
}

.block-product-water_products-item .nav-pills .nav-link.active {
  color: #00c95d;
  background-color: transparent;
  border-bottom: 1px;
  position: relative;
}

.block-product-water_products-item .nav-pills .nav-link.active::after {
  content: '';
  height: 2px;
  background-color: #00c95d;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.block-product-energy p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-product-energy p:last-child {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .block-product-energy p:last-child {
    margin-bottom: 15px;
  }
}

.block-meter-cost p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-meter-cost span {
  display: block;
  margin-bottom: 15px;
  font-family: RobotoLight;
  font-size: 15px;
  line-height: 22px;
}

.block-meter-cost a:hover, .block-meter-cost a:focus {
  text-decoration: none;
  outline: none;
}

.block-meter-cost #accordion .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 15px;
  position: relative;
}

.block-meter-cost #accordion .panel:last-child:before {
  display: none;
}

.block-meter-cost #accordion .panel-heading {
  padding: 0;
  border: none;
  border-radius: 0;
  position: relative;
}

.block-meter-cost #accordion .panel-title a {
  display: block;
  padding: 10px 30px 10px 60px;
  margin: 0;
  background: #ffffff;
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #1d2932;
  border-radius: 0;
  position: relative;
}

.block-meter-cost #accordion .panel-title a:before,
.block-meter-cost #accordion .panel-title a.collapsed:before {
  content: "\ea3e";
  font-family: icomoon;
  font-weight: 900;
  width: 40px;
  line-height: 40px;
  background: #00c95d;
  border: 1px solid #1d2932;
  border-radius: 3px;
  font-size: 17px;
  color: #1d2932;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}

.block-meter-cost #accordion .panel-title a.collapsed:before {
  content: "\ea3e";
  background: #fff;
  border: 1px solid #6e8898;
  color: #000;
}

.block-meter-cost #accordion .panel-body {
  padding: 10px 30px 10px 30px;
  margin-left: 40px;
  background: #fff;
  border-top: none;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 1px;
}

.block-meter-cost_img {
  text-align: center;
}

.block-meter-cost_img img {
  width: 50%;
}

@media (min-width: 992px) {
  .block-meter-cost_img img {
    width: 100%;
  }
}

.block-valves .bttn-text {
  margin-bottom: 30px;
}

.block-valves p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-valves .button-budget {
  margin-top: 50px;
}

.block-valves_img {
  text-align: center;
}

.block-valves_img img {
  width: 100%;
  margin-bottom: 20px;
}

.block-install-meter img {
  margin-bottom: 20px;
  width: 50%;
}

@media (min-width: 992px) {
  .block-install-meter img {
    width: 80%;
  }
}

.block-install-meter p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-about h2 {
  margin-bottom: 30px;
}

.block-about p {
  font-size: 15px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
}

.block-about p img {
  width: 50%;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .block-about p img {
    width: 80%;
  }
}

.block-commit p {
  font-size: 15px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
}

.block-commit img {
  width: 100%;
  margin-top: 30px;
}

.block-work p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-work a {
  position: relative;
  color: #00c95d;
}

.block-work a:hover::after {
  content: '';
  height: 1px;
  background-color: #1d2932;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.block-read-meter p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-quality p, .block-quality ul {
  font-size: 15px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
}

.activated {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 15%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 20px;
}

@media (min-width: 992px) {
  .activated {
    bottom: 60%;
  }
}

.hide {
  display: none;
}

.block-cookies_panel--buttons {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .block-cookies_panel--buttons {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 100px;
  }
}

.block-cookies_panel--buttons p {
  width: 200px;
  padding: 10px;
  border-radius: 30px;
  text-align: center;
}

.block-cookies_panel--buttons p:nth-child(1) {
  background-color: #00c95d;
}

.block-cookies_panel--buttons p:nth-child(1):hover {
  color: #ffffff;
}

.block-cookies_panel--buttons p:nth-child(2) {
  background-color: #1d2932;
  color: #ffffff;
}

.block-cookies_panel--buttons p:nth-child(2):hover {
  color: #00c95d;
}

.block-cookies_panel--buttons p:nth-child(3) {
  border: 1px solid #1d2932;
}

.block-cookies_panel--buttons p:nth-child(3):hover {
  background-color: #00c95d;
}

.block-cookies_panel--buttons p:hover {
  cursor: pointer;
}

.block-form {
  padding: 20px;
}

.block-form body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.block-form .container {
  width: 100%;
  border-radius: 7px;
  border: 1px solid #1d2932;
  text-align: center;
  padding: 30px 35px 0 35px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .block-form .container {
    width: 35%;
  }
}

.block-form .container .progress-bar {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.block-form .container .progress-bar .step {
  text-align: center;
  width: 100%;
}

.block-form .container .progress-bar .step p {
  font-size: 12px;
  line-height: 15px;
}

.block-form .container .progress-bar .step .num {
  position: relative;
  width: 25px;
  height: 25px;
  border: 2px solid #1d2932;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
}

.block-form .container .form-princ {
  width: 100%;
  transition: margin-left 0.8s ease-in-out;
}

.block-form .container .form-princ form {
  display: flex;
  width: 660%;
}

.block-form .container .form-princ form .page {
  width: 100%;
  margin-right: 10%;
}

.block-form .container .form-princ form .page .form-page {
  display: none;
}

.block-form .container .form-princ form .page button {
  font-weight: bold;
  width: 100%;
  background-color: #1d2932;
  border: 1px solid #1d2932;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0 20px 0;
  color: #ffffff;
  transition: 0.5s ease;
}

.block-form .container .form-princ form .page button:first-child {
  margin-bottom: 0;
}

.block-form .container .form-princ form .page button:hover {
  background-color: #00c95d;
  color: #1d2932;
  cursor: pointer;
}

.block-form .container .form-princ form .page button[disabled] {
  background-color: gray;
  color: #ffffff;
  border: none;
}

.block-form .container .form-princ form .page .textfield label {
  font-weight: initial;
  width: 100%;
  background-color: #424242;
  border: 1px solid #1d2932;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0 20px 0;
  color: #ffffff;
  transition: 0.5s ease;
}

.block-form .container .form-princ form .page .textfield label:first-child {
  margin-bottom: 0;
}

.block-form .container .form-princ form .page .textfield label:hover {
  color: #00c95d;
  cursor: pointer;
}

.block-form .container .form-princ form .page .textfield input[type="checkbox"] {
  display: none;
}

.block-form .container .form-princ form .page .select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.block-form .container .form-princ form .page .select:hover {
  cursor: pointer;
}

.block-form .container .form-princ form .page .bttn-nav {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.block-form .container .form-princ form .page .bttn-nav-send p:last-child {
  display: none;
}

.block-form .container .form-princ form .page .form-block {
  position: relative;
}

.block-form .container .form-princ form .page .form-block input {
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
  width: 200px;
  margin: 10px;
  line-height: 6ex;
  width: 100%;
}

.block-form .container .form-princ form .page .form-block label {
  position: absolute;
  top: -1.5ex;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
}

.block-form .container .form-princ form .page .form-block.bill {
  display: none;
  margin-bottom: 20px;
}

.block-form .container .form-princ form .page .form-block.virtual-key, .block-form .container .form-princ form .page .form-block.virtual-access, .block-form .container .form-princ form .page .form-block.fault-cold, .block-form .container .form-princ form .page .form-block.fault-hot, .block-form .container .form-princ form .page .form-block.fault-heat {
  display: none;
  align-items: left;
}

.block-form .container .form-princ form .page .form-block.virtual-key label, .block-form .container .form-princ form .page .form-block.virtual-access label, .block-form .container .form-princ form .page .form-block.fault-cold label, .block-form .container .form-princ form .page .form-block.fault-hot label, .block-form .container .form-princ form .page .form-block.fault-heat label {
  position: initial;
  width: 90%;
  text-align: left;
}

.block-form .container .form-princ form .page .text-fault {
  display: none;
  text-align: left;
}

.block-form .container .form-princ form .page textarea {
  width: 100% !important;
  height: 150px;
}

.block-form .move-page1 {
  margin-left: -165%;
  transition: margin-left 0.8s ease-in-out;
}

.block-form .move-page2 {
  margin-left: -330%;
  transition: margin-left 0.8s ease-in-out;
}

.block-form .move-page3 {
  margin-left: -495%;
  transition: margin-left 0.8s ease-in-out;
}

.block-form .showForm {
  display: block !important;
}

.block-form .showFormFlex {
  display: flex !important;
}

.block-form .green-num {
  color: #00c95d;
}

.block-form .modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.block-form .modal .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.block-form .modal .modal-content .close {
  position: absolute;
  right: 5px;
  top: -10px;
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

.block-form .modal .modal-content .close:hover, .block-form .modal .modal-content .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.block-budget .resultBud {
  color: #00c95d;
  font-size: 15px;
  line-height: 20px;
  margin: 20px 0 10px 0;
}

@media (min-width: 992px) {
  .block-budget .resultBud {
    font-size: 18px;
    line-height: 23px;
    margin: 15px 0 10px 0;
  }
}

.block-budget p, .block-budget select {
  font-size: 12px;
  line-height: 15px;
}

@media (min-width: 992px) {
  .block-budget p, .block-budget select {
    font-size: 15px;
    line-height: 20px;
  }
}

.block-budget .select {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.block-budget .select:hover {
  cursor: pointer;
}

.block-budget .input-bud {
  position: relative;
}

.block-budget .input-bud input {
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
  width: 200px;
  line-height: 6ex;
  width: 100%;
}

.block-budget .input-bud label {
  position: absolute;
  top: -1.5ex;
  z-index: 1;
  left: 1em;
  background-color: white;
  padding: 0 5px;
}

.block-budget .input-bud.bill {
  display: none;
  margin-bottom: 20px;
}

.block-budget .input-bud.virtual-key, .block-budget .input-bud.virtual-access, .block-budget .input-bud.fault-cold, .block-budget .input-bud.fault-hot, .block-budget .input-bud.fault-heat {
  display: none;
  align-items: left;
}

.block-budget .input-bud.virtual-key label, .block-budget .input-bud.virtual-access label, .block-budget .input-bud.fault-cold label, .block-budget .input-bud.fault-hot label, .block-budget .input-bud.fault-heat label {
  position: initial;
  width: 90%;
  text-align: left;
}

.block-budget textarea {
  width: 100% !important;
  height: 150px;
}

.block-budget .checkProtect {
  display: flex;
}

.block-budget .checkProtect input[type="checkbox"] {
  display: grid;
  place-content: center;
  margin-right: 10px;
}

.block-budget .checkProtect input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.block-budget .checkProtect input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.block-budget #sendBud {
  font-weight: bold;
  width: 100%;
  background-color: #1d2932;
  border: 1px solid #1d2932;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0 20px 0;
  color: #ffffff;
  transition: 0.5s ease;
}

.block-budget #sendBud:first-child {
  margin-bottom: 0;
}

.block-budget #sendBud:hover {
  background-color: #00c95d;
  color: #1d2932;
  cursor: pointer;
}

.block-budget #url {
  display: none;
}

.block-image img {
  width: 100%;
}

.block-landing-aranconta h2 {
  margin-top: 0;
}

.block-landing-aranconta p, .block-landing-aranconta ul {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-landing-aranconta .landing-video {
  width: 100%;
}

.block-landing-aranconta .landing-video iframe {
  width: 100%;
  height: 200px;
}

@media (min-width: 992px) {
  .block-landing-aranconta .landing-video iframe {
    height: 300px;
  }
}

.block-landing-aranconta .img-rep {
  width: 100%;
  text-align: center;
}

.block-landing-aranconta .img-rep img {
  width: 50%;
}

@media (min-width: 992px) {
  .block-landing-aranconta .img-rep img {
    width: 40%;
  }
}

.block-title-pages {
  margin-bottom: 40px;
}

.block-title-pages_text #meta {
  display: none;
}

.block-title-pages_text h1 {
  text-align: center;
  position: relative;
}

@media (min-width: 768px) {
  .block-title-pages_text h1 {
    text-align: left;
  }
}

.block-title-pages_text h1::after {
  content: '';
  height: 2px;
  background-color: #00c95d;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}

.block-accordion p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-accordion .button-budget {
  margin-bottom: 20px;
}

.block-accordion .card {
  width: 100%;
}

.block-accordion .card .img-bg-1 {
  position: relative;
}

.block-accordion .card .img-bg-1::before {
  content: "";
  background-image: url(../images/products/cuando-instalar-rcc.webp);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  background-size: cover;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-1::before {
    height: 100%;
  }
}

.block-accordion .card .img-bg-1 .text {
  z-index: 1;
  width: 100%;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-1 .text {
    width: 75%;
  }
}

.block-accordion .card .img-bg-2 {
  position: relative;
}

.block-accordion .card .img-bg-2::before {
  content: "";
  background-image: url(../images/products/porque-instalar-rcc.webp);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  background-size: cover;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-2::before {
    height: 100%;
  }
}

.block-accordion .card .img-bg-2 .text {
  z-index: 1;
  width: 100%;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-2 .text {
    width: 75%;
  }
}

.block-accordion .card .img-bg-3 {
  position: relative;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-3 {
    height: 260px;
  }
}

.block-accordion .card .img-bg-3::before {
  content: "";
  background-image: url(../images/products/ahorrar-consumo.webp);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  background-size: cover;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-3::before {
    height: 100%;
  }
}

.block-accordion .card .img-bg-3 .text {
  z-index: 1;
  width: 100%;
}

@media (min-width: 992px) {
  .block-accordion .card .img-bg-3 .text {
    width: 75%;
  }
}

.block-accordion .card-body {
  display: block;
}

@media (min-width: 992px) {
  .block-accordion .card-body {
    display: flex !important;
  }
}

.block-accordion .card-body_text {
  padding: 5px;
}

.block-accordion .card-body_text--title {
  font-weight: bold;
  color: #1d2932;
}

.block-accordion .card p {
  width: 100%;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-accordion .card p a {
  display: block;
  padding: 30px 60px 33px 10px;
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid green;
  position: relative;
  width: 100%;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  font-size: 16px;
  line-height: 23px;
}

.block-accordion .card p a:hover {
  cursor: pointer;
}

@media (min-width: 992px) {
  .block-accordion .card p a {
    padding: 30px;
  }
}

.block-accordion .card p a::after {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 50%;
  font-family: icomoon;
  transition: transform .2s ease-in-out;
}

.block-accordion .card p a[aria-expanded="true"]::after {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  right: 10px;
  font-family: icomoon;
  transform: rotate(180deg);
  transition: transform .2s ease-in-out;
}

.block-accordion .card .card-body_img {
  margin: 20px 0 0 0;
}

@media (min-width: 992px) {
  .block-accordion .card .card-body_img {
    margin: 0 0 0 0;
  }
}

.block-video_video {
  height: 100%;
  margin: 0 0 20px 0;
}

.block-video_video .iframe {
  height: 100%;
  width: 100%;
}

.block-video .button-budget {
  display: block;
  text-align: center;
}

.block-video .button-budget:first-child {
  margin: 20px 0 20px 0;
  width: 100%;
}

@media (min-width: 992px) {
  .block-video .button-budget {
    width: auto;
  }
}

.block-video p, .block-video h3 {
  text-align: center;
}

@media (min-width: 992px) {
  .block-video p, .block-video h3 {
    text-align: left;
  }
}

.block-tabs p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-tabs_box {
  height: 100%;
  width: 100%;
  text-align: center;
  list-style: none;
  padding: 0;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .block-tabs_box {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    width: 50%;
    margin-inline: auto;
  }
}

.block-tabs_box li {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

@media (min-width: 992px) {
  .block-tabs_box li {
    margin: initial;
  }
}

.block-tabs_box--1 {
  background-color: #1d2932;
  color: #00c95d;
  grid-row-start: 2;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 4;
  position: relative;
}

.block-tabs_box--1::before {
  border: 4px solid #1d2932;
  content: "";
  height: 100%;
  left: 0.6em;
  position: absolute;
  top: 0.6em;
  width: 100%;
  z-index: -1;
}

.block-tabs_box--2 {
  background-color: #00c95d;
  border: 2px solid #1d2932;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
  position: relative;
}

.block-tabs_box--2::before {
  border: 4px solid #1d2932;
  content: "";
  height: 100%;
  left: -0.6em;
  position: absolute;
  top: -0.6em;
  width: 100%;
  z-index: -1;
}

.block-tabs_box--3 {
  background-color: #1d2932;
  color: #00c95d;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 5;
  position: relative;
}

.block-tabs_box--3::before {
  border: 4px solid #1d2932;
  content: "";
  height: 100%;
  left: 0.6em;
  position: absolute;
  top: -0.6em;
  width: 100%;
  z-index: -1;
}

.block-tabs_box--4 {
  background-color: #00c95d;
  border: 2px solid #1d2932;
  grid-row-start: 3;
  grid-column-start: 2;
  grid-row-end: 4;
  grid-column-end: 4;
  position: relative;
}

.block-tabs_box--4::before {
  border: 4px solid #1d2932;
  content: "";
  height: 100%;
  left: -0.6em;
  position: absolute;
  top: 0.6em;
  width: 100%;
  z-index: -1;
}

.block-tabs_banner {
  height: 50px;
  width: 100%;
  padding: 10px;
  position: relative;
  text-align: center;
  margin-bottom: 70px;
  margin-top: 50px;
}

.block-tabs_banner span {
  color: #1d2932;
  font-size: 20px;
  line-height: 25px;
}

@media (min-width: 992px) {
  .block-tabs_banner span {
    font-size: 30px;
    line-height: 35px;
  }
}

.block-tabs_banner span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 50px solid transparent;
  border-bottom: 3px solid #00c95d;
}

@media (min-width: 768px) {
  .block-tabs .uni-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .block-tabs .uni-content {
    flex-direction: row;
  }
}

.block-tabs .uni-content_img {
  text-align: center;
}

.block-tabs .uni-content_img img {
  width: 100%;
}

@media (min-width: 768px) {
  .block-tabs .uni-content_img img {
    width: 50%;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .block-tabs .uni-content_img img {
    width: inherit;
  }
}

.block-tabs #snit .nav-pills {
  display: flex;
  justify-content: center;
}

.block-tabs .nav-pills .nav-link.active {
  color: #00c95d;
  background-color: transparent;
  border-bottom: 1px;
  position: relative;
}

.block-tabs .nav-pills .nav-link.active::after {
  content: '';
  height: 2px;
  background-color: #00c95d;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.block-tabs .nav-pills {
  display: flex;
}

@media (min-width: 992px) {
  .block-tabs .nav-pills {
    flex-direction: column;
  }
}

.block-tabs a {
  color: #1d2932;
  position: relative;
}

.block-tabs a:hover::after {
  content: '';
  height: 2px;
  background-color: #1d2932;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.block-breadcrumbs {
  margin-bottom: 20px;
}

.block-breadcrumbs_links a {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 16px;
  line-height: 23px;
}

.block-breadcrumbs_links a:hover {
  color: #00c95d;
}

.block-breadcrumbs_links p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 16px;
  line-height: 23px;
}

@media (min-width: 992px) {
  .block-breadcrumbs_links p {
    font-size: 15px;
    line-height: 22px;
  }
}

.block-breadcrumbs_links--text {
  position: relative;
}

.block-breadcrumbs_links--text::after {
  content: '';
  height: 2px;
  background-color: #00c95d;
  position: absolute;
  bottom: -6px;
  left: 12px;
  right: 0;
}

.block-icon-text {
  position: relative;
}

.block-icon-text p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-icon-text_img {
  text-align: center;
}

@media (min-width: 768px) {
  .block-icon-text_img {
    margin-top: 50px;
  }
}

.block-icon-text_img img {
  width: 50%;
}

@media (min-width: 992px) {
  .block-icon-text_img img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .block-icon-text_row {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .block-icon-text_row::before {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #00c95d;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
  }
}

.block-icon-text ul li {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-grid .grid {
  text-align: center;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
}

@media (min-width: 480px) {
  .block-grid .grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    justify-items: center;
  }
}

@media (min-width: 992px) {
  .block-grid .grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
}

.block-grid_catalog {
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
  padding: 20px;
}

@media (min-width: 768px) {
  .block-grid_catalog {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

.block-grid_catalog-card {
  position: relative;
  width: 100%;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .block-grid_catalog-card {
    width: 80%;
  }
}

.block-grid_catalog-card img {
  width: 100%;
  height: 100%;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 500ms ease-out;
}

.block-grid_catalog-card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 41, 50, 0.8);
  transition: all 500ms ease-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 10px;
}

.block-grid_catalog-card a h2 {
  text-align: center;
  color: #ffffff;
  transition: all 500ms ease-in;
}

.block-grid_catalog-card:hover a {
  opacity: 1;
  visibility: visible;
}

.block-grid_catalog-card:hover a h2 {
  text-align: center;
  transition: all 500ms ease-out;
  transform: translateY(80%);
  color: #ffffff;
}

.block-map {
  position: relative;
}

.block-map_banner {
  height: 50px;
  width: 100%;
  padding: 10px;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.block-map_banner span {
  color: #1d2932;
  font-size: 20px;
  line-height: 25px;
}

@media (min-width: 992px) {
  .block-map_banner span {
    font-size: 30px;
    line-height: 35px;
  }
}

.block-map_banner span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-top: 50px solid transparent;
  border-bottom: 3px solid #00c95d;
}

.block-map .text span {
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

@media (min-width: 992px) {
  .block-map .text {
    width: 40%;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    left: 20px;
  }
}

@media (min-width: 1200px) {
  .block-map .text {
    font-size: 18px;
    line-height: 23px;
    position: absolute;
    left: 20px;
  }
}

.block-map .text .card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .block-map .text .card {
    align-items: initial;
  }
}

.block-map .text .card iframe {
  width: 100%;
  height: 200px;
}

.block-map .text .card_item {
  box-shadow: 1px 6px 15px -6px #000000;
  border-radius: 8px;
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .block-map .text .card_item {
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid #00c95d;
    border-radius: 8px;
    min-width: 350px;
    max-width: 350px;
    height: 220px;
  }
}

.block-map .text .card_item p {
  font-size: 12px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  margin-bottom: 10px;
}

.block-map .text .card_item p a {
  font-size: 12px;
  line-height: 22px;
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  position: relative;
}

.block-map .text .card_item p a:hover {
  color: #00c95d;
}

.block-map .text .card_item p a:hover::after {
  content: '';
  height: 1px;
  background-color: #1d2932;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.block-map .text .card_item .city {
  color: #00c95d;
  font-size: 16px;
  line-height: 23px;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
  margin-top: 20px;
}

.block-map svg path {
  fill: #00c95d;
  stroke: #000000;
}

.block-map svg .ggm {
  fill: #ffffff;
  stroke: #1d2932;
  stroke-width: 4;
}

.block-map svg .ggm:hover {
  fill: #1d2932;
  stroke: #ffffff;
  stroke-width: 4;
  cursor: pointer;
}

.block-contact {
  margin-bottom: 30px;
}

.block-contact .cont {
  background-color: lightgrey;
  border-radius: 20px;
  padding: 25px;
  height: 300px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .block-contact .cont {
    margin-bottom: 0;
  }
}

.block-contact p {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
}

.block-contact h2 {
  position: relative;
  margin-bottom: 20px;
  color: #1d2932;
}

.block-contact h2::after {
  content: '';
  height: 1px;
  background-color: #1d2932;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.block-contact a {
  position: relative;
}

.block-contact a:hover {
  color: #00c95d;
}

.block-contact a:hover::after {
  content: '';
  height: 1px;
  background-color: #1d2932;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.block-contact .green {
  color: #1d2932;
  font-family: "RobotoBold", arial, helvetica, sans-serif;
}

.block-contact .green a {
  font-family: "RobotoLight", arial, helvetica, sans-serif;
}

.block-news img {
  width: 100%;
}

.block-news #list-news {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  justify-items: center;
}

@media (min-width: 992px) {
  .block-news #list-news {
    grid-template-columns: repeat(2, 1fr);
  }
}

.block-news .controls {
  margin-top: 50px;
  text-align: center;
}

.block-news .controls #back {
  position: relative;
}

.block-news .controls #back::before {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  right: 10px;
  font-family: icomoon;
  transform: rotate(90deg);
}

.block-news .controls #next {
  position: relative;
}

.block-news .controls #next::before {
  content: "\ea3e";
  font-size: 18px;
  position: absolute;
  left: 10px;
  font-family: icomoon;
  transform: rotate(270deg);
}

.block-news .card-new {
  width: 90%;
  margin: 20px 0 50px 0;
  border: 1px solid black;
  box-shadow: 1px 6px 15px -1px #000000;
  min-height: 430px;
  border-radius: 20px 20px 0 0;
}

@media (min-width: 992px) {
  .block-news .card-new {
    min-height: 450px;
  }
}

.block-news .card-new:hover {
  box-shadow: 1px 6px 25px -1px #000000;
}

.block-news .card-new a:hover h3 {
  color: #00c95d;
}

.block-news .card-new a .image img {
  border-radius: 20px 20px 0 0;
  width: 100% !important;
}

.block-news .card-new a .text {
  padding: 20px;
}
