@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-budget {


    .resultBud {
        color: $textColorPrimary;
        @include fontSize(15,20);
        margin: 20px 0 10px 0;
        @media(min-width: $md) {
            @include fontSize(18,23);
            margin: 15px 0 10px 0;
        }
    }
    p, select {
        @include fontSize(12,15);
        @media(min-width: $md) {
            @include fontSize(15,20);
        }
    }
    .select {
        width: 50%;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
        }
    }
    .input-bud {

        position: relative;

        input {
            border: 1px solid gray;
            border-radius: 6px;
            position: relative;
            width: 200px;
            line-height: 6ex;
            width: 100%;
        }

        label {
            position: absolute;
            top: -1.5ex;
            z-index: 1;
            left: 1em;
            background-color: white;
            padding: 0 5px;
        }

        &.bill {
            display: none;
            margin-bottom: 20px;
        }
        &.virtual-key, &.virtual-access, &.fault-cold, &.fault-hot, &.fault-heat  {
            display: none;
            align-items: left;
            label {
                position: initial;
                width: 90%;
                text-align: left;
            }
        }
    }
    textarea {
        width: 100% !important;
        height: 150px;
    }

    .checkProtect {
        display: flex;
        input[type="checkbox"]  {
            display: grid;
            place-content: center;
            margin-right: 10px;
                &::before {
                    content: "";
                    width: 0.65em;
                    height: 0.65em;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em var(--form-control-color);
                }

                &:checked::before {
                    transform: scale(1);
                }
            } 
    }

    #sendBud {
        font-weight: bold;
        width: 100%;
        background-color: $bg-blue;
        border: 1px solid $bg-blue;
        border-radius: 5px;
        padding: 10px;
        margin: 20px 0 20px 0;
        color: $textColorWhite;
        transition: 0.5s ease;
        &:first-child {
            margin-bottom: 0;
        }

        &:hover {
            background-color: $bg-primary;
            color: $textColorBlue;
            cursor: pointer;
        }
    }

    #url {
        display: none;
    }
}