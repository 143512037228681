@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-contact {
    margin-bottom: 30px;
    .cont {
        background-color: lightgrey;
        border-radius: 20px;
        padding: 25px;
        height: 300px;
        margin-bottom: 20px;
        @media(min-width: $md) {
            margin-bottom: 0;
        }
    }
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    h2 {
        position: relative;
        margin-bottom: 20px;
        color: $textColorBlue;
        &::after {
            content: '';
            height: 1px;
            background-color: $bg-blue;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
        }
    }
    a {
        position: relative;
        &:hover {
            color: $textColorPrimary;
            &::after {
                content: '';
                height: 1px;
                background-color: $bg-blue;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
            }
        }
    }
    .green {
        color: $textColorBlue;
        font-family: $fontBold;
        a {
            font-family: $fontLight;
        }
    }
}