@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";
.block-title-pages {
    margin-bottom: 40px;
    &_text {
        #meta {
            display: none;
        }
        h1 {
            text-align: center;
            position: relative;
            @media(min-width: $sm) {
                text-align: left;
            }
            &::after {
                content: '';
                height: 2px;
                background-color: $bg-primary;
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
            }
        }
    }
}