@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-grid {
    
    .grid {
        text-align: center;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        @media(min-width: $xs) {
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
            justify-items: center;
        }
        @media(min-width: $md) {
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
        }
    }

    &_catalog {
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        padding: 20px;
        @media(min-width: $sm) {
            grid-template-rows: repeat(3, 1fr);
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }
        &-card {
            position: relative;
            width: 100%;
            margin-top: 30px;
            @media(min-width: $sm) {
                width: 80%;
            }
            img {
                width: 100%;
                height: 100%;
                box-shadow: 0 15px 25px rgba(0,0,0,0.50);
                cursor: pointer;
                transition: all 500ms ease-out;
             }
             a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(29, 41, 50,0.8);
                transition: all 500ms ease-out;
                opacity: 0;
                visibility: hidden;
                text-align: center;
                padding: 10px;
                h2 {
                    text-align: center;
                    color: $textColorWhite;
                    transition: all 500ms ease-in;
                }
                
             }
             &:hover {
                a {
                    opacity: 1;
                    visibility: visible;

                    h2 {
                        text-align: center;
                        transition: all 500ms ease-out;
                        transform: translateY(80%);
                        color: $textColorWhite;
                    }
                }
            }
        } 
    }
}