@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-slider-cards {
    overflow: hidden;
    @media(min-width: $md) {
        overflow: initial;

    }
        .swiper-info-install {
            margin-top: 35px;
            .swiper-wrapper {
                .swiper-slide {
                    box-shadow: 1px 6px 15px -1px #000000;
                    min-height: 430px;
                    @media(min-width: $md) {
                        min-height: 450px;
                    }
                    .swiper-slide_img {
                        img {
                            width: 100% !important;
                        }
                    }
                    &_text {
                        padding: 20px;
                        p {
                            @include fontSize(15,22);
                        }
                        h2 {
                            color: $textColorBlue;
                            text-align: left;
                            margin: initial;
                        }
                    }
                }
            }
        }
    }