@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-energy {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    &_img {
        width: 100%;
        img {
            width: 100%;
            text-align: center;
            position: relative;
            @media(min-width: $md) {
                width: 70%;
            }
        }
        &--usage {
            width: 100%;
            text-align: center;
            margin: 20px 0 30px 0;
            @media(min-width: $md) {
                width: 80%;
            }
            img {
                width: 100%;
            }
        }
    }
}