@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-media {
    h2 {
        text-align: center;
        color: $textColorBlue;
    }
    .swiper-media {
		overflow: hidden;
		@media(min-width: $md) {
			overflow: initial;

		}
        margin-top: 35px;
        .swiper-wrapper {
            .swiper-slide {
                a {
                    p {
                        text-align: center;
                    }
                }
            }
        }
    } 
}