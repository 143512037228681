@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-install-meter {
    img {
        margin-bottom: 20px;
        width: 50%;
        @media(min-width: $md) {
            width: 80%;
        }
    }
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
}