@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-about {
    h2 {
        margin-bottom: 30px;
    }
    p {
        @include fontSize(15,22);
        font-family: $fontLight;
        img {
            width: 50%;
            margin-bottom: 50px;
            @media(min-width: $md) {
                width: 80%;
            }
        }
    }
}