@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.activated {
    display: block;
    position: fixed;
    z-index:1000;
    top: 0;
    bottom: 15%;
    left: 0;
    right: 0;
    background-color: $bg-white;
    padding: 20px;
    @media(min-width: $md) {
        bottom: 60%;
    }
}
.hide {
    display: none;
}
.block-cookies {
    &_panel {
        &--buttons {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media(min-width: $md) {
                flex-direction: row;
                justify-content: space-around;
                margin-top: 100px;
            }
            p {
                width: 200px;
                padding: 10px;
                border-radius: 30px;
                text-align: center;
                &:nth-child(1) {
                    background-color: $bg-primary;
                    &:hover {
                        color: $textColorWhite;
                    }
                }
                &:nth-child(2) {
                    background-color: $bg-blue;
                    color: $textColorWhite;
                    &:hover {
                        color: $textColorPrimary;
                    }
                }
                &:nth-child(3) {
                    border: 1px solid $bg-blue;
                    &:hover {
                        background-color: $bg-primary;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}