@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-services {
    margin-top: 30px;
    @media(min-width: $md) {
        margin-top: 50px;
    }
    position: relative;
    h2 {
        margin-bottom: 40px;
    }
    .card {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: $lg) {
            position: absolute;
            justify-content: space-around;
            right: -20%;
            z-index: 1;
        }
        &_item {
            box-shadow: 1px 6px 15px -6px #000000;
            border-radius: 8px;
            padding: 15px;
            width: 80%;
            margin-top: 20px;
            &:first-child {
                margin-top: 0;
            }
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                @media(min-width: $lg) {
                    flex-direction: row;
                }
                span {
                    @include fontSize(18,24);
                    font-weight: bold;
                }
            }
            @media(min-width: $lg) {
                flex-direction: row;
                background-color: $bg-white;
                border: 1px solid $bg-primary;
                border-radius: 8px;
                min-width: 500px;
                max-width: 500px;
            }
        }
    }
    .img {
        display: none;
        @media(min-width: $lg) {
            display: block;
        }
    }
}