@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";
.block-product-water {

    h3 {
        @media(min-width: $sm) {
            min-height: 50px;
        }
    }

    &_text {
        margin-bottom: 60px;
        @media(min-width: $md) {
            display: block;
        }
        @media(min-width: $lg) {
            display: flex;
        }
        &--text {
            width: 100%;
            @media(min-width: $lg) {
                width: 80%;
            }
            p {
                font-family: $fontLight;
                @include fontSize(15,22);
            }
        }

        &--img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
            @media(min-width: $md) {
                width: 20%;
                height: 20%;
                margin-top: inherit;
            }
            img {
                width: 25%;
                @media(min-width: $md) {
                    display: none;
                }
                
                @media(min-width: $lg) {
                    display: block;
                    width: 50%;
                }
            }
        }


    }

    &_banner {
        height: 50px;
        width: 100%;
        padding: 10px;
        position: relative;
        text-align: center;
        &-title {
            color: $textColorBlue;
            @include fontSize(20,25);
            @media(min-width: $md) {
                @include fontSize(30,35);
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                border-top: 50px solid transparent;
                border-bottom: 3px solid $bg-primary;
            }
        }
    }
    &_products {  
        margin-top: 70px;
        margin-bottom: 70px;
        width: 100%;
        display: block;    
        @media(min-width: $sm) {
            margin-top: 50px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around; 
        }
        &-item {
            margin-top: 40px;
            -webkit-box-shadow: 0px 3px 9px 6px rgba(189,189,189,1);
            -moz-box-shadow: 0px 3px 9px 6px rgba(189,189,189,1);
            box-shadow: 0px 3px 9px 6px rgba(189,189,189,1);
            border-radius: 3%;
            display: flex;
            flex-direction: column; 
            padding: 10%;      
            @media(min-width: $sm) {
                width: 45%;
                padding: 2%;
                margin-top: 2%;
            }   
            @media(min-width: $md) {
                width: 40%;
            }
            @media(min-width: $lg) {
                width: 30%;
            }

            span {
                font-family: $fontLight;
                @include fontSize(15,22);
            }

            &--img {
                min-height: 200px;
                img {
                    width: 100%;
                }
            }

            span {
                @include fontSize(14,20)
            }
            
            &--type {
                padding: 0;
                text-align: left;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                @media(min-width: $md) {
                    min-height: 120px;
                }
                li {
                    width: 100%;
                    a {
                        font-family: $fontLight;
                        @include fontSize(16,23);
                        margin: 0 6px 10px 0;
                        color: $blue;
                        border: 1px solid $blue;
                        text-decoration: none;
                        padding: 4px 8px 4px 8px;
                        border-radius: 3px;
                        display: inline-flex;
                        width: 100%;
                        justify-content: center;
                        &:hover {
                            background-color: $green;
                            color: $blue;
                        }
                    }
                }
            }
            .nav-pills {
                margin-bottom: 30px;
                justify-content: center;
            }
            

            .nav-pills .nav-link.active {
                color: $textColorPrimary;
                background-color: transparent;
                border-bottom: 1px;
                position: relative;
                &::after {
                    content: '';
                    height: 2px;
                    background-color: $textColorPrimary;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    right: 0;
        }
    }
        }
    }
    
}