@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-map {
    position: relative;
    &_banner {
        height: 50px;
        width: 100%;
        padding: 10px;
        position: relative;
        text-align: center;
        margin-bottom: 40px;
        span {
            color: $textColorBlue;
            @include fontSize(20,25);
            @media(min-width: $md) {
                @include fontSize(30,35);
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                border-top: 50px solid transparent;
                border-bottom: 3px solid $bg-primary;
            }
        }
    }
    .text {
        span {
            font-family: $fontBold;
            @include fontSize(15,22);
        }
        @media(min-width: $md) {
            width: 40%;
            @include fontSize(16,20);
            position: absolute;
            left:20px;
        }
        @media(min-width: $lg) {
            @include fontSize(18,23);
            position: absolute;
            left:20px;
        }
        .card {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media(min-width: $md) {
                align-items: initial;
            }
            iframe {
                width: 100%;
                height: 200px;
            }
            &_item {
                box-shadow: 1px 6px 15px -6px #000000;
                border-radius: 8px;
                padding: 10px;
                width: 90%;
                margin-top: 20px;
                margin-bottom: 20px;
                @media(min-width: $md) {
                    flex-direction: row;
                    background-color: $bg-white;
                    border: 1px solid $bg-primary;
                    border-radius: 8px;
                    min-width: 350px;
                    max-width: 350px;
                    height: 220px;
                }
                p {
                    @include fontSize(12,22);
                    font-family: $fontLight;
                    margin-bottom: 10px;
                    a {
                        @include fontSize(12,22);
                        font-family: $fontLight;
                        position: relative;
                        &:hover {
                            color: $textColorPrimary;
                            &::after {
                                content: '';
                                height: 1px;
                                background-color: $bg-blue;
                                position: absolute;
                                bottom: -2px;
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }
                .city {
                    color: $textColorPrimary;
                    @include fontSize(16,23);
                    font-family: $fontBold;
                    margin-top: 20px;
                }
            }
        }
    }
    svg {
        path {
            fill: $bg-primary;
            stroke: $black;
        }
        .ggm {
            fill: $bg-white;
            stroke: $textColorBlue;
            stroke-width: 4;
            &:hover {
                fill: $bg-blue;
                stroke: $textColorWhite;
                stroke-width: 4;
                cursor: pointer;
            }
        }
    }
}