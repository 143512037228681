@import "../../scss/general/vars";
@import "../../scss/general/mixins";
.header {
	margin-bottom: initial;
	  .nav{
		background: $bg-white;
		justify-content: space-between;
		padding: 10px;
		@media(min-width: $md) {
			width: 100%;
		}
		&_logo {
			img {
				width: 199px;
				height: 46px;
				@media(min-width: $md) { 
					width: 250px;
					height: 60px;
				}
			}
		}
		ul {
			margin: 0px;
			padding: 0px;
			list-style: none;
			text-align: right;
			li {
				display: inline-block;
				cursor: pointer;
				padding: 6px;
				a {
					text-transform: uppercase;
					color: $textColorBlack;
					font-family: RobotoLight;
					text-decoration: none;
					display: block;
					padding: 13px;
					@media(min-width: $md) { 
						padding: 16px;
					}
					@include fontSize(14,20);
					&:hover {
						color: $textColorPrimary;
					}
					&:last-child {
						padding-right: 0;
					}
					
				}
			  }
		  } 
		  .icon-menu, .icon-close-menu{
			display: none;
		  }
		  @media(max-width: 992px) {
			.icon-menu, .icon-close-menu {
				display: block;
				text-align: right;
				padding: 10px 15px;
				font-size: 24px;
				cursor: pointer;
		   
			}
			ul {
			  display: none;
			  background: $bg-white;
			}
			ul li{
			  width: 100%;
			  text-align: left;
			  padding: 0px;
			}
			.drop .fa-sort-desc {
			  display: none;
			}
		  }
	  }
	  
}
.header-2 {
	margin-bottom: initial;
	position: relative;
	&::before {
		content: "";
		background-color: white;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.nav{
		background: $bg-white;
		justify-content: space-around;
		padding-bottom: 10px;
		@media(min-width: $md) {
			width: 100%;
			padding: 10px 0;
		}
		&_logo {
			img {
				width: 200px;
				height: 50px;
				@media(min-width: $md) { 
					width: 250px;
					height: 60px;
				}
			}
		}
		.responsive-menu {
			position: relative;
			margin: 0px;
			padding: 0px;
			list-style: none;
			text-align: right;
			@media(min-width: $md) {
				width: 100%;
				display: flex;
				justify-content: space-around;
				text-align: right;
			}

			.online{
				@media(min-width: $md) {
					display: none;
				}
			}
			li {
				display: inline-block;
				cursor: pointer;
				padding: 6px;
				@media(min-width: $md) {
					display: flex;
					justify-content: space-around;
					text-align: center;
				}
				a {
					text-transform: uppercase;
					font-family: $fontBold;
					color: $textColorBlack;
					text-decoration: none;
					display: block;
					padding: 16px;
					font-weight: bold;
					&:hover {
						color: $textColorPrimary;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			  }
			  .submenu {
				display: none;
				z-index: 2;
				background-color: white;
				left: 0;
				right: 0;
				top: inherit;
				text-align: left;
				padding: 10px;
				@include fontSize(16,23);
				@media(min-width: $md) {
					position: absolute;
					box-shadow: none;
					border-top: 2px solid $bg-blue;
					border-bottom: 2px solid $bg-blue;
					top: 100%;
				}
			  }
			  .active {
				display: block;
			  }
		  } 
		  .icon-menu, .icon-close-menu{
			display: none;
		  }
		  @media(max-width: 992px) {
			.icon-menu, .icon-close-menu {
				display: block;
				text-align: right;
				padding: 10px 15px;
				font-size: 24px;
				cursor: pointer;
		   
			}
			ul {
			  display: none;
			  background: $bg-white;
			}
			ul li{
			  width: 100%;
			  text-align: left;
			  padding: 0px;
			}
			.drop .fa-sort-desc {
			  display: none;
			}
		  }
	  }
}
