@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-landing-aranconta {

    h2 {
        margin-top: 0;
    }
    p, ul {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    .landing-video {
        width: 100%;

        iframe {
            width: 100%;
            height: 200px;
            @media(min-width: $md) {
                height: 300px;
            }
        }
    }
    
    .img-rep {
        width: 100%;
        text-align: center;
        img {
                width: 50%;
                @media(min-width: $md) {
                    width: 40%;
                }
        }
    }
}