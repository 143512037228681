@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-breadcrumbs {
    margin-bottom: 20px;
    &_links {
        a {
            font-family: $fontLight;
            @include fontSize(16,23);
            &:hover {
                color: $textColorPrimary;
            }
        }
        p {
            font-family: $fontLight;
            @include fontSize(16,23);
            @media(min-width: $md) {
                @include fontSize(15,22);
            }
        }
        &--text {
            position: relative;
            &::after {
                content: '';
                height: 2px;
                background-color: $textColorPrimary;
                position: absolute;
                bottom: -6px;
                left: 12px;
                right: 0;
            }
        }
    }
}