@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

$flex: flex;
$spaceAr: space-around;
$auto: auto;
$inlineBlock: inline-block;


//Pantalla pequeña
$smallMobile: 330px;

//Movil horizontal y tablet vertical
$mobile: 768px;

//Tablet horizontal
$tablet: 1024px;

//Monitor
$monitor: 1200px;

//El header y footer
$includeTopHeightSmallMobile: 130px;
$includeTopHeightMobile: 150px;
$includeTopHeightTablet: 350px;

body {
    overflow-x: hidden;
    position: relative;
	&::before {
        @media(min-width: $md) { 
            content: "91 303 00 00";
            position: fixed;
            bottom: 0;
            left: 0;
            font-size: 24px;
            line-height: 28px;
            color: $textColorBlue;
            background-color: $textColorPrimary;
            z-index: 2;
            padding: 15px;
            border-radius: 0 5px 0 0;
	    }
    }
}

section {
    margin-bottom: 20px;
    @media(min-width: $md) {
        margin-bottom: 50px;
    }
}

strong {
    font-family: $fontBold;    
}

h1 {
    @include fontSize(20,27);
    font-family: $fontBold;
    color: $textColorBlue;
    text-align: center;
    @media(min-width: $md) {
        @include fontSize(25,32);
    }
}
h2 {
    @include fontSize(18,25);
    color: $textColorPrimary;
    font-family: $fontBold;
    text-align: center;
    margin: 20px 0 20px 0;
    @media(min-width: $md) {
        @include fontSize(20,27);
        text-align: left;
    }
}
h3 {
    @include fontSize(16,23);
    color: $textColorBlue;
    font-family: $fontBold;
    text-align: left;
    @media(min-width: $md) {
        @include fontSize(18,25);
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: $textColorBlue;
    &:hover {
        text-decoration: none;
        color: $textColorBlue;
    }
}

.button-budget {
    background-color: $bg-primary;
    border: 3px solid $bg-blue;
    color: $textColorBlue;
    font-family: RobotoBold;
    @include fontSize(14,21);
    padding: 7px;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
    text-align: center;
    @media(min-width: $md) {
        display: initial;
        text-align: left;
        @include fontSize(16,23);
        padding: 15px;
    }
    &:hover {
        background-color: $bg-blue;
        border: 3px solid $bg-primary;
        color: $textColorPrimary;
    }
}


//flex general lineal
@mixin flexLine {
    display: flex;
    flex-direction: row;
}

//flex general espacio alrededor lineal
@mixin flexLineAround {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

//flex general espacio entre componentes lineal
@mixin flexLineBetween { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

//flex general columna
@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

//flex general espacio alrededor columna
@mixin flexColumnAround {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

//flex general espacio entre componentes columna
@mixin flexColumnBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

//Colores
$black: #000000;
$white: #FFFFFF;
$blue: #3886CC;
$green: #548235;
$grey: #AEAEAE;
$transparent: transparent;
$none: none;
$red: #ff0000;
$aqua: aqua;
$whiteGrey: #bbb;
$greyNews: #f2f2f2;
$greyImageSlide: #717171;
$natureContainer: #F0F0F0;
$greyClock: #B0B0B0;

//Borde redondeado botones
$borderCircleButtons: 5px;

//Sombras de caja
$shadowBox: 0px 0px 20px 0px;

//posiciones lineales
$start: start;
$end: end;
body {
    position: relative;
}