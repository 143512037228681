@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-slider {
	.swiper {
		position: relative;
		width: 100%;
		height: 100%;
		.swiper-wrapper {
			.swiper-slide {
			  text-align: center;
			  font-size: 18px;
			  background: #fff;
			}
		  
			.swiper-slide {
			  display: block;
			  width: 100%;
			  height: 100%;
			  object-fit: cover;
			  p {
				img {
					width: 100%;
				}
			  }
			}
		}
		overflow: hidden;
		@media(min-width: $md) {
			overflow: initial;

		}
	}
	.swiper-pagination {
		position: relative !important;
		width: 100%;
		&-bullet {
			width: 8px;
			height: 8px;
			margin: 11px;
			&-active {
				background: $bg-primary;
			}
			@media(min-width: $md) {
				width: 10px;
				height: 10px;
				margin: 21px;

			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}