@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-opinions {
    h2 {
        text-align: center;
        color: $textColorBlue;
    }
    .swiper-opinions {
		overflow: hidden;
		@media(min-width: $md) {
			overflow: initial;

		}
        width: 100%;
        padding: 0 0 50px 0;
        @media(min-width: $md) {
            padding: 50px 0 70px 0;
        }
        .swiper-slide {
            box-shadow: 1px 6px 15px -6px #000000;
            background-position: center;
            background-size: cover;
            padding: 20px;
            border-radius: 8px;
            &_img {
                img {
                    width: 50%;
                }
            }
            &_title{
                font-family: $fontBold;
                color: $textColorBlue;
                width: 90%;
                font-style: italic;
                min-height: 80px;
                max-height: 80px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
                -webkit-box-orient: vertical;
                @media(min-width: $lg) {
                    -webkit-line-clamp: 3;
                            line-clamp: 3; 
                }
            }
            &_subtitle {
                font-family: $fontLight;
                color: $textColorBlue;
                width: 80%;
                min-height: 70px;
                max-height: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                        line-clamp: 4; 
                -webkit-box-orient: vertical;
                @media(min-width: $lg) {
                    -webkit-line-clamp: 3;
                            line-clamp: 3; 
                    min-height: 50px;
                    max-height: 50px;
                }
            }
        }
    }
}