//VARIABLES:

// SIZE
$xxs: "360px";
$xs: "480px";
$sm: "768px";
$md: "992px";
$lg: "1200px";
$xlg: "1600px";

// COLORS

// colors names
//---------------
$green:#00c95d;
$blue: #1d2932;
$white: #ffffff;
$black: #000000;
$gray: #424242;
$gray-medium: #5D5D5D;
$gray-light: #EFEFEF;
$gray-light-2: #F1F1F1;
$red:#C63147;
$yellow:#C7C146;

$init-value: #A0A0A0;
$net-operation: #424242;
$net-result: #6EA59E;
$final-value: #CE9133;


$thead:#9f9f9f;
$tsubHeader:#BCBCBC;
$tgreen:#6EA59E;
$tline:#C8C9C9;

//bg
$bg-white: $white;
$bg-black: $black;
$bg-primary: $green;
$bg-blue: $blue;
$bg-red: $red;
$bg-gray: $gray;
$bg-gray-light: $gray-light;
$bg-gray-medium:$gray-medium;
$bg-main:$gray-light-2;


$textColorPrimary:$green;
$textColorBlue:$blue;
$textColorGray:$gray;
$textColorBlack:$black;
$textColorWhite:$white;




// FONT FAMILY
// basic
//---------------


$fontRegular: "RobotoRegular", arial, helvetica, sans-serif;
$fontBold: "RobotoBold", arial, helvetica, sans-serif;
$fontLight: "RobotoLight", arial, helvetica, sans-serif;
$fontMedium: "RobotoMedium", arial, helvetica, sans-serif;

