@font-face {
    font-family: 'RobotoLight';
    src: url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'RobotoMedium';
    src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}