@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-meter-cost {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    span {
        display: block;
        margin-bottom: 15px;
        font-family: RobotoLight;
        @include fontSize(15,22);
    }
    a:hover,a:focus{
        text-decoration: none;
        outline: none;
    }
    #accordion {
        .panel{
            border: none;
            border-radius: 0;
            box-shadow: none;
            margin-bottom: 15px;
            position: relative;
        }
    }
    #accordion .panel:last-child:before{ display: none; }
    #accordion .panel-heading{
        padding: 0;
        border: none;
        border-radius: 0;
        position: relative;
    }
    #accordion .panel-title a{
        display: block;
        padding: 10px 30px 10px 60px;
        margin: 0;
        background: $white;
        @include fontSize(21,26);
        font-weight: 700;
        letter-spacing: 1px;
        color: $bg-blue;
        border-radius: 0;
        position: relative;
    }
    #accordion .panel-title a:before,
    #accordion .panel-title a.collapsed:before{
        content: "\ea3e";
        font-family: icomoon;
        font-weight: 900;
        width: 40px;
        line-height: 40px;
        background: $bg-primary;
        border: 1px solid $bg-blue;
        border-radius: 3px;
        font-size: 17px;
        color: $bg-blue;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease 0s;
    }
    #accordion .panel-title a.collapsed:before{
        content: "\ea3e";
        background: #fff;
        border: 1px solid #6e8898;
        color: #000;
    }
    #accordion .panel-body{
        padding: 10px 30px 10px 30px;
        margin-left: 40px;
        background: #fff;
        border-top: none;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 1px;
    }

    &_img {
        text-align: center;
        img {
            width: 50%;
            @media(min-width: $md) {
                width: 100%;
            }
        }
    }
}