@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-remote { 
    p, ul {
        @include fontSize(15,22);
        font-family: $fontLight;
    }

    &_img {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: $md) {
            flex-direction: row;
            justify-content: space-around;
        }
    }

}