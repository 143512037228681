@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-video {

    &_video {
        height: 100%;
        margin: 0 0 20px 0;
        .iframe {
            height: 100%;
            width: 100%;
        }
    }

    .button-budget {
        display: block;
        text-align: center;
        &:first-child {
            margin: 20px 0 20px 0;
            width: 100%;
        }
        @media(min-width: $md) {
            width: auto;
        }
    }

    p, h3 {
        text-align: center;
        @media(min-width: $md) {
            text-align: left;
        }
    }
}