@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-work {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    a {
        position: relative;
        color: $textColorPrimary;
        &:hover {
            &::after {
                content: '';
                height: 1px;
                background-color: $bg-blue;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
            }
        }
    }
}