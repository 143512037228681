@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-customer {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $bg-blue;
        border-top: 5px solid $bg-primary;
        border-bottom: 5px solid $bg-primary;
        right: 0;
        left: 0;
    }
    h2 {
        margin-top: 40px;
        text-align: center;
    }
    .card {
        padding: 40px;
        a {
            img{
                width: 70%;
                @media(min-width: $xs) {
                    width: 45%;
                }
                @media(min-width: $md) {
                    width: 100%;
                }
            }
        }
    }
}