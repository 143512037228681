@import "../../scss/general/vars";
@import "../../scss/general/mixins";

.footer {
	.footer-title {
		a {
			color: $textColorPrimary;
			&:not([href]):not([tabindex]):hover, &:not([href]):not([tabindex]):focus {
				color: $textColorPrimary;
			}
		}
	}
	background-color: $bg-blue;
	display: flex;
	flex-direction: column;
	margin-bottom: initial;
	padding: 45px 25px 25px 25px;
	@media(min-width: $md) {
		margin-top: 50px;
		flex-direction: column;
		justify-content: space-between;
	}
	&-container_img {
		width: 100%;
		text-align: center;
		@media(min-width: $lg) {
			text-align: end;
		}
		img {
			width: 50%;
			margin-bottom: 20px;
			@media(min-width: $md) {
				width: 30%;
				margin-bottom: 15px;
			}
			@media(min-width: $lg) {
				width: 25%;
				margin-bottom: inherit;
			}
		}
	}
	&-container_media {
		width: 100%;
		@media(min-width: $md) {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		@media(min-width: $lg) {
			width: 100%;
			justify-content: end;
		}
		&-nav {
			width: 100%;
			@media(min-width: $md) {
				width: 20%;
			}
			&-media {
				display: flex;
				padding: 30px 0 0 0;
				justify-content: space-between;
				text-align: center;
				width: 100%;
				list-style: none;
				img {
					width: 60%;
				}
			}
		}
	}
	&-container_options {
		width: 100%;
		@media(min-width: $lg) {
			width: 65%;
		}
		&-nav {
			display: none;
			@media(min-width: $md) {
				display: flex;
				justify-content: space-around;
				align-items: inherit;
			}
			&-media {
				list-style: none;
				padding: 0;
				width: 100%;
				@media(min-width: $md) {
					width: 20%;
					min-height: 400px;
				}
				&--green {
						a {
							font-family: $fontBold;
							position: relative;
							color: $textColorPrimary !important;
							&::after {
								content: "";
								position: absolute;
								width: 100%;
								height: 1px;
								background-color: $textColorPrimary;
								bottom: -10px;
								left: 0;
							}
						}
						.soft-green {
							font-weight: 100;
							span {
								font-weight: bold;
							}
						}
				}
				li {
					padding-top: 20px;
					padding-bottom: 20px;
					a {
						color: $textColorWhite;
						@include fontSize(16,23);
					}
					.soft-white {
						font-weight: 100;
						font-family: RobotoLight;
						span {
							font-family: $fontBold;
						}
						&:hover {
							span {
								color: $textColorPrimary;
							}
						}
					}
				}
			}
		}
		

		&-responsive {
			@media(min-width: $md) {
				display: none;
			}
		}
	}
	&-container_copy {
		p {
			font-family: $fontBold;
			color: $textColorWhite;
			@include fontSize(10,17);
			text-align: center;
			@media(min-width: $lg) {
				@include fontSize(18,25);
				text-align: right;
			}
		}
	}
}

.collapsible-link {
	width: 100%;
	position: relative;
	text-align: left;
  }
  
  .collapsible-link {
	&::after{
		content: "\ea3e";
		font-size: 18px;
		position: absolute;
		right: 10px;
		top: 0;
		font-family: icomoon;
		transition: transform .2s ease-in-out;
	}

	&::before {
		content: '';
		height: 2px;
		background-color: $textColorWhite;
		position: absolute;
		bottom: -10px;
		left: 0;
		right: 0;
	}

	&:last-child {
		margin-bottom: 30px;
	}
  }
  
  .collapsible-link[aria-expanded="true"]::after {
	content: "\ea3e";
	font-size: 18px;
	position: absolute;
	right: 10px;
	top: 0;
	font-family: icomoon;
	transform: rotate(180deg);
	transition: transform .2s ease-in-out;
  }
