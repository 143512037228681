@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-tabs {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    &_box {
        height: 100%;
        width: 100%;
        text-align: center;
        list-style: none;
        padding: 0;
        margin-top: 30px;
        @media(min-width: $md) {
            display: grid; 
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;
            width: 50%;
            margin-inline: auto;
        }
        li  {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px;
            @media(min-width: $md) {
                margin: initial;
            }
        }
        &--1 {
            background-color: $bg-blue;
            color: $textColorPrimary;
            grid-row-start: 2;
            grid-column-start: 3;
            grid-row-end: 3;
            grid-column-end: 4;
            position: relative;
            &::before {
                border: 4px solid $bg-blue;
                content: "";
                height: 100%;
                left: 0.6em;
                position: absolute;
                top: 0.6em;
                width: 100%;
                z-index: -1;
            }
         }
         &--2 {
            background-color: $bg-primary; 
            border: 2px solid $bg-blue;
            grid-row-start: 1;
            grid-column-start: 2;
            grid-row-end: 2;
            grid-column-end: 3;
            position: relative;
            &::before {
                border: 4px solid $bg-blue;
                content: "";
                height: 100%;
                left: -0.6em;
                position: absolute;
                top: -0.6em;
                width: 100%;
                z-index: -1;
            }
         }
         &--3 {
            background-color: $bg-blue;
            color: $textColorPrimary;
            grid-row-start: 1;
            grid-column-start: 3;
            grid-row-end: 2;
            grid-column-end: 5;
            position: relative;
            &::before {
                border: 4px solid $bg-blue;
                content: "";
                height: 100%;
                left: 0.6em;
                position: absolute;
                top: -0.6em;
                width: 100%;
                z-index: -1;
            }
            
         }
         &--4 {
            background-color: $bg-primary; 
            border: 2px solid $bg-blue;
            grid-row-start: 3;
            grid-column-start: 2;
            grid-row-end: 4;
            grid-column-end: 4;
            position: relative;
            &::before {
                border: 4px solid $bg-blue;
                content: "";
                height: 100%;
                left: -0.6em;
                position: absolute;
                top: 0.6em;
                width: 100%;
                z-index: -1;
            }
         }
    }
     
    &_banner {
        height: 50px;
        width: 100%;
        padding: 10px;
        position: relative;
        text-align: center;
        margin-bottom: 70px;
        margin-top: 50px;
        span {
            color: $textColorBlue;
            @include fontSize(20,25);
            @media(min-width: $md) {
                @include fontSize(30,35);
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                border-top: 50px solid transparent;
                border-bottom: 3px solid $textColorPrimary;
            }
        }
    }
    .uni-content {
        @media(min-width: $sm) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        @media(min-width: $lg) {
            flex-direction: row;
        }
        &_img {
            text-align: center;
            img {
                width: 100%;
                @media(min-width: $sm) {
                    width: 50%;
                    text-align: center;
                }
                @media(min-width: $md) {
                    width: inherit;
                }
            }
        }
    }

    #snit {
        .nav-pills {
            display: flex;
            justify-content: center;
        }
    }

    .nav-pills .nav-link.active {
        color: $textColorPrimary;
        background-color: transparent;
        border-bottom: 1px;
        position: relative;
        &::after {
            content: '';
            height: 2px;
            background-color: $textColorPrimary;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
        }
    }
    .nav-pills {
        display: flex;
        @media(min-width: $md) {
            flex-direction: column;
        }
    }
    .date-range {
    }
    
    a {
        color: $textColorBlue;
        position: relative;
    }
    a:hover {
        &::after {
            content: '';
            height: 2px;
            background-color: $textColorBlue;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
        }
    }
}