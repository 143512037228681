@import "../../scss/general/vars";
@import "../../scss/general/mixins";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?si5qyp');
  src:  url('../fonts/icomoon/icomoon.eot?si5qyp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?si5qyp') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?si5qyp') format('woff'),
    url('../fonts/icomoon/icomoon.svg?si5qyp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
  color: $bg-black;
}
.icon-menu:before {
  content: "\e9bd";
  color: $bg-black;
}
.icon-close-menu:before {
  content: "\ea0f";
  color: $bg-black;
}
.icon-arrow-up:before {
  content: "\ea3a";
  color: $bg-black;
}
.icon-arrow-down:before {
  content: "\ea3e";
  color: $bg-black;
}
