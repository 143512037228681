@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";
.block-product-energy {
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
        &:last-child {
            margin-bottom: 15px;
            @media(min-width: $md) {
                margin-bottom: 15px;
            }
        }
    }
}