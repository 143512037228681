@import "../../base/scss/general/vars";
@import "../../base/scss/general/mixins";

.block-icon-text {
    position: relative;
    p {
        font-family: $fontLight;
        @include fontSize(15,22);
    }
    &_img {
        @media(min-width: $sm) {
            margin-top: 50px;
        }
        text-align: center;
        img {
            width: 50%;
            @media(min-width: $md) {
                width: 100%;
            }
        }
    }
    &_row{
        @media(min-width: $sm) {
            margin-top: 50px;
        }
        &::before {    
            @media(min-width: $md) {
                content: '';
                height: 2px;
                width: 100%;
                background-color: #00c95d;
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
            }
        } 
    }

    ul {
        li {
            font-family: $fontLight;
            @include fontSize(15,22);
        }
    }
}